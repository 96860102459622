import Vue from 'vue'
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload, {
  preLoad: 1.1,
  attempt: 1,
  observer: true,

  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1,
  },
  filter: {
    webp(listener: any, options: any) {
      if (!options.supportWebp) return
      if (typeof listener.src.split('?')[1] === 'undefined') {
        listener.src += '?fm=webp'
      } else {
        listener.src += '&fm=webp'
      }
    },
  },
})
