import { ActionTree, Store } from 'vuex'
import { ActionContext } from 'vuex/types'
// import { Context } from '@nuxt/types'

import { initialiseStores } from '~/utils/store-accessor'

// RootStateを追加
export const state = () => ({})
export type RootState = ReturnType<typeof state>

const initializer = (store: Store<any>) => initialiseStores(store)
export const plugins = [initializer]

export const actions: ActionTree<any, any> = {
  nuxtServerInit: async (context: ActionContext<RootState, RootState>) => {
    await context.dispatch('product/initPostCategory')
    await context.dispatch('product/initPostsDate')
  },
}

export * from '~/utils/store-accessor'
