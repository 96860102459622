/* eslint-disable import/no-mutable-exports */
import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'
import Product from '~/store/product'

let productStore: Product

function initialiseStores(store: Store<any>): void {
  productStore = getModule(Product, store)
}

export { initialiseStores, productStore }
