import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import Vuex from 'vuex'
import dayjs from 'dayjs'
import { Dictionary, Post, Param, PostsDate, Category } from '~/types/blog'
import { createClient } from '~/plugins/contentful'
const store = new Vuex.Store<any>({})

const client = createClient()

const ORDER = '-fields.publishDate'

const PAGE = 6

export interface ProductState {
  isCookieAccepted: boolean | false
  latestPosts: Dictionary<Post>
  posts: Dictionary<Post>
  currentPost: Post | null
  page: number
  pagesTotal: number
  tags: string[]
  postsDate: Dictionary<PostsDate>
  categories: Dictionary<Category>
  relatedPosts: Dictionary<Post>
}

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'product',
  store,
  dynamic: true,
})
export default class Product extends VuexModule implements ProductState {
  isCookieAccepted: boolean = false
  latestPosts: Dictionary<Post> = {}
  posts: Dictionary<Post> = {}
  currentPost: Post | null = null
  page: number = 1
  pagesTotal: number = 0
  tags: string[] = []
  postsDate: Dictionary<PostsDate> = {}
  categories: Dictionary<Category> = {}
  relatedPosts: Dictionary<Post> = {}

  @Mutation
  setLatestPosts(payload: Dictionary<Post>) {
    this.latestPosts = payload
  }

  @Mutation
  setPosts(payload: Dictionary<Post>) {
    this.posts = payload
  }

  @Mutation
  setCurrentPost(payload: Post | null) {
    this.currentPost = payload
  }

  @Mutation
  setPage(payload: number) {
    this.page = payload
  }

  @Mutation
  setPagesTotal(payload: number) {
    this.pagesTotal = payload
  }

  @Mutation
  setTags(payload: string[]) {
    this.tags = payload
  }

  @Mutation
  setPostsDate(payload: Dictionary<PostsDate>) {
    this.postsDate = payload
  }

  @Mutation
  setCategories(payload: Dictionary<Category>) {
    this.categories = payload
  }

  @Mutation
  setRelatedPosts(payload: Dictionary<Post>) {
    this.relatedPosts = payload
  }

  @Action({ rawError: true })
  setPotst(entry: Post | null) {
    this.setCurrentPost(entry)
  }

  @Action({ rawError: true })
  setPotstsGenerate(payload: any) {
    this.setPosts(payload.items)
    this.setPagesTotal(Math.ceil(payload.total / PAGE))
  }

  @Action({ rawError: true })
  async initPosts(params: Param) {
    // 投稿詳細ページ
    if (params.slug !== '') {
      // const LATEST_PAGE = 6;

      // await client
      //   .getEntries({
      //     content_type: process.env.CTF_BLOG_POST_TYPE_ID,
      //     order: ORDER,
      //     limit: LATEST_PAGE,
      //   })
      //   .then((entries: any) => {
      //     this.setCurrentPost(entries.items);
      //   });

      await client
        .getEntries({
          content_type: process.env.CTF_BLOG_POST_TYPE_ID,
          'fields.slug': params.slug,
          order: ORDER,
        })
        .then((entries: any) => {
          // const currentPost = entries.items.filter((item: any) => {
          //   return item.fields.slug === params.slug
          // })

          this.setCurrentPost(entries.items[0])
        })
      return
    }

    // 日付（年-月）による絞り込み
    this.setPage(this.page)
    if (params.date !== '') {
      // const LATEST_PAGE = 6
      await client
        .getEntries({
          content_type: process.env.CTF_BLOG_POST_TYPE_ID,
          order: ORDER,
          'fields.publishDate[gte]': dayjs(params.date).format(),
          'fields.publishDate[lte]': dayjs(params.date).endOf('month').format(),
          skip: (this.page - 1) * PAGE,
          limit: PAGE,
        })
        .then((entries: any) => {
          const dateArray: string[] = []
          entries.items.forEach((item: any) => {
            const date = dayjs(item.fields.publishDate)
            dateArray.push(date.format('YYYY-MM'))
          })
          const counts: Dictionary<number> = {}
          for (let i = 0; i < dateArray.length; i++) {
            const key = dateArray[i]
            counts[key] = counts[key] ? counts[key] + 1 : 1
          }
          const countDate: Array<{ date: string; count: string }> = []
          Object.keys(counts).forEach((key) =>
            countDate.push({ date: key, count: String(counts[key]) })
          )

          this.setPosts(entries.items)
          this.setPagesTotal(Math.ceil(entries.total / PAGE))
          // commit('setPostsDate', countDate)
        })
      return
    }
    // カテゴリーによる絞り
    if (params.category !== '') {
      // const LATEST_PAGE = 6
      await client
        .getEntries({
          order: ORDER,
          content_type: process.env.CTF_BLOG_POST_TYPE_ID,
          'fields.category.sys.id': this.categories[params.category].id,
          skip: (this.page - 1) * PAGE,
          limit: PAGE,
        })
        .then((entries: any) => {
          this.setPosts(entries.items)
          this.setPagesTotal(Math.ceil(entries.total / PAGE))
        })
      return
    }

    // デフォルト
    await client
      .getEntries({
        order: ORDER,
        content_type: process.env.CTF_BLOG_POST_TYPE_ID,
        skip: (this.page - 1) * PAGE,
        limit: PAGE,
      })
      .then((entries: any) => {
        const dateArray: string[] = []
        entries.items.forEach((item: any) => {
          const date = dayjs(item.fields.publishDate)
          dateArray.push(date.format('YYYY-MM'))
        })
        const counts: Dictionary<number> = {}
        for (let i = 0; i < dateArray.length; i++) {
          const key = dateArray[i]
          counts[key] = counts[key] ? counts[key] + 1 : 1
        }
        const countDate: Array<{ date: string; count: string }> = []
        Object.keys(counts).forEach((key) =>
          countDate.push({ date: key, count: String(counts[key]) })
        )
        this.setPosts(entries.items)
        this.setPagesTotal(Math.ceil(entries.total / PAGE))
      })
  }

  @Action({ rawError: true, commit: 'setRelatedPosts' })
  async getRelatedPostData(params: Param) {
    const result = await client
      .getEntries({
        content_type: process.env.CTF_BLOG_POST_TYPE_ID,
        links_to_entry: this.categories[params.category].id,
        order: ORDER,
        limit: 3,
        'fields.slug[ne]': params.slug,
      })
      .then((entries: any) => {
        return entries.items
      })
    return result
  }

  @Action({ rawError: true, commit: 'setPostsDate' })
  async initPostsDate() {
    const countDate: Array<{ date: string; count: string }> = []
    await client
      .getEntries({
        content_type: process.env.CTF_BLOG_POST_TYPE_ID,
        order: ORDER,
        // skip: (state.page - 1) * PAGE,
        // limit: PAGE
      })
      .then((entries: any) => {
        const dateArray: string[] = []
        entries.items.forEach((item: any) => {
          const date = dayjs(item.fields.publishDate)
          dateArray.push(date.format('YYYY-MM'))
        })
        const counts: Dictionary<number> = {}
        for (let i = 0; i < dateArray.length; i++) {
          const key = dateArray[i]
          counts[key] = counts[key] ? counts[key] + 1 : 1
        }
        Object.keys(counts).forEach((key) =>
          countDate.push({ date: key, count: String(counts[key]) })
        )
      })
    return countDate
  }

  @Action({ rawError: true, commit: 'setCategories' })
  async initPostCategory() {
    const catArray: Dictionary<{
      id: string
      slug: string
      name: string
      count: string
    }> = {}
    await client
      .getEntries({
        content_type: process.env.CTF_CATEGORY_TYPE_ID,
      })
      .then((entries: any) => {
        entries.items.forEach((item: any) => {
          catArray[item.fields.slug] = {
            id: item.sys.id,
            slug: item.fields.slug,
            name: item.fields.name,
            count: '',
          }
        })
      })
    for (const [, value] of Object.entries(catArray)) {
      await client
        .getEntries({
          links_to_entry: value.id,
        })
        .then((entries: any) => {
          catArray[value.slug] = {
            id: value.id,
            slug: value.slug,
            name: value.name,
            count: entries.total,
          }
        })
    }
    return catArray
  }
}
