







import { Vue, Component } from 'nuxt-property-decorator'
const HeaderBlock = () => import('~/components/layouts/Header.vue')
const FooterText = () => import('~/components/layouts/FooterText.vue')

@Component({
  components: {
    HeaderBlock,
    FooterText,
  },
})
export default class Index extends Vue {}
